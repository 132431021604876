import {useCallback} from 'react';
import type {ResponsiveValue} from '@tempo/core';
import {useTheme} from '@tempo/core';

export function getNextSizesFromChakraSizes(
	sizes: ResponsiveValue<string>,
	breakpoints: Record<string, string>,
): string {
	if (!sizes || typeof sizes === 'string') return sizes;
	const breakpointEntries = Object.entries(breakpoints);
	const sections: string[] = [];

	if (Array.isArray(sizes)) {
		sizes.forEach((size, i) => {
			if (!size) return;
			if (i !== 0) {
				sections.push(`(max-width: ${breakpointEntries[i][1]})`);
			}
			sections.push(`${size},`);
		});
		return sections
			.reverse()
			.join(' ')
			.replace(/^,*(.*?),*$/, '$1');
	} else {
		Object.entries(sizes).forEach(([bpName, size], i) => {
			if (!size) return;
			if (i !== 0) {
				sections.push(`(max-width: ${breakpoints[bpName]})`);
			}
			sections.push(`${size},`);
		});
		return sections
			.reverse()
			.join(' ')
			.replace(/^,*(.*?),*$/, '$1')
			.split(', ')
			.reverse()
			.join(', ');
	}
}

export const useNextJsSize = (): ((
	sizes: ResponsiveValue<string>,
) => string) => {
	const {breakpoints} = useTheme();
	return useCallback(
		(sizes: ResponsiveValue<string>) => {
			return getNextSizesFromChakraSizes(sizes, breakpoints);
		},
		[breakpoints],
	);
};
