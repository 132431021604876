import type {FunctionComponent} from 'react';
import {useTranslation} from '@app/hooks/use-translation';

import type {ButtonProps} from '@tempo/core';
import {Button} from '@tempo/core';
import {useTracking} from '@app/modules/tracking';
import {useAuthLink} from '@app/hooks/use-link';

export const HeaderLoginBtn: FunctionComponent<ButtonProps> = (props) => {
	const {t} = useTranslation();
	const {getLoginLink} = useAuthLink();
	const {trackClick} = useTracking();
	return (
		<Button
			as="a"
			variant="outline"
			onClick={trackClick({
				eventaction: 'login-button',
				eventcategory: 'header',
			})}
			href={getLoginLink()}
			{...props}
		>
			{t('generic_action_login_standalone-features')}
		</Button>
	);
};

export const HeaderRegisterBtn: FunctionComponent<ButtonProps> = (props) => {
	const {t} = useTranslation();
	const {getRegistrationLink} = useAuthLink();
	const {trackClick} = useTracking();
	return (
		<Button
			as="a"
			onClick={trackClick({
				eventaction: 'register-button',
				eventcategory: 'header',
			})}
			href={getRegistrationLink()}
			{...props}
		>
			{t('generic_action_signup_standalone-features')}
		</Button>
	);
};
