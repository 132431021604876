import React, {useState} from 'react';
import {useTranslation} from '@app/hooks/use-translation';

import type {IconButtonProps} from '@tempo/core';
import {IconButton, useDisclosure} from '@tempo/core';
import {ReorderIcon} from '@tempo/icons';
import {useTracking} from '@app/modules/tracking';

import dynamic from 'next/dynamic';

const LazyDrawerMenu = dynamic(() => import('./drawer-menu'), {
	loading: () => null,
});

export const HeaderBurgerMenu: React.FunctionComponent<
	Omit<IconButtonProps, 'aria-label'>
> = (props) => {
	const {t} = useTranslation();
	const [hasOpened, setHasOpened] = useState(false);
	const {isOpen, onOpen, onClose} = useDisclosure();
	const btnRef = React.useRef<HTMLButtonElement>(null);
	const {trackClick} = useTracking();

	const handleOpen = () => {
		setHasOpened(true);
		onOpen();
		trackClick({eventaction: 'open-menu', 'non-interactional': true})();
	};

	return (
		<>
			<IconButton
				ref={btnRef}
				aria-label={t('generic_action_login_standalone-features')}
				variant="ghost"
				size="medium"
				icon={<ReorderIcon />}
				fontSize="22px"
				onClick={handleOpen}
				{...props}
			/>
			{hasOpened && (
				<LazyDrawerMenu
					isOpen={isOpen}
					onClose={onClose}
					finalFocusRef={btnRef}
				/>
			)}
		</>
	);
};
