import type {FunctionComponent} from 'react';

// Components
import {LandingTopBar} from '@tempo/landing';
import {
	TopBarLogo,
	TopBarList,
	TopBarActions,
	TopBarDeezerLogo,
	Box,
	tempo,
} from '@tempo/core';
import {HeaderBurgerMenu} from './header-burger-menu';
import {HeaderLoginBtn, HeaderRegisterBtn} from './header-login-btn';
import {HeaderOpenPlayer} from './header-open-player';
import NextLink from 'next/link';

// Hooks
import {useLocation} from '@app/hooks/use-location';
import {useUser} from '@deezer/react-user';
import {useTranslation} from '@app/hooks/use-translation';
import {useTracking} from '@app/modules/tracking';
import {usePathname} from 'next/navigation';

const TopbarExposedLink = tempo(NextLink, {
	baseStyle: {
		_hover: {color: 'text.accent.primary.hovered'},
		fontWeight: 'bold',
		display: {base: 'none', md: 'inline-flex'},
	},
});
TopbarExposedLink.defaultProps = {
	variant: 'default',
	as: NextLink,
};

const Header: FunctionComponent = () => {
	const {getDeezerUrl} = useLocation();
	const {t} = useTranslation();
	const [user] = useUser();
	const {trackClick} = useTracking();
	const pathname = usePathname();

	return (
		<Box
			borderBottom={{base: 'none', sm: 'solid 1px'}}
			borderBottomColor="divider.neutral.primary.default !important"
			width="100%"
		>
			<LandingTopBar data-cy="header">
				<TopBarLogo>
					<NextLink
						href={getDeezerUrl('/', {withQuery: false})}
						data-cy="deezer-logo"
					>
						<TopBarDeezerLogo />
					</NextLink>
				</TopBarLogo>
				<TopBarList justifyContent="right">
					<TopbarExposedLink
						href={getDeezerUrl('/offers')}
						onClick={trackClick({
							eventaction: 'offers-link',
							eventcategory: 'header',
						})}
					>
						{t('footer_title_plans_standalone-features')}
					</TopbarExposedLink>
					<TopbarExposedLink
						color={
							pathname?.includes('/features')
								? 'text.accent.primary.default'
								: 'text.neutral.primary.default'
						}
						href="/features"
						onClick={trackClick({
							eventaction: 'features-link',
							eventcategory: 'header',
						})}
					>
						{t('Fonctionnalités')}
					</TopbarExposedLink>
					<TopbarExposedLink
						href={getDeezerUrl('/channels/explore/')}
						onClick={trackClick({
							eventaction: 'channels-link',
							eventcategory: 'header',
						})}
					>
						{t('Musique')}
					</TopbarExposedLink>
				</TopBarList>
				<TopBarActions>
					{user?.isConnected() ? (
						<HeaderOpenPlayer
							display={{base: 'none', sm: 'inline-flex'}}
							size="small"
						/>
					) : (
						<>
							<HeaderLoginBtn
								display={{base: 'none', sm: 'inline-flex'}}
								size="small"
							/>
							<HeaderRegisterBtn
								display={{base: 'none', sm: 'inline-flex'}}
								size="small"
							/>
						</>
					)}
					<HeaderBurgerMenu display={{base: 'inline-flex', sm: 'none'}} />
				</TopBarActions>
			</LandingTopBar>
		</Box>
	);
};

export default Header;
