import type {FunctionComponent} from 'react';
import {useTranslation} from '@app/hooks/use-translation';

import type {ButtonProps} from '@tempo/core';
import {Button} from '@tempo/core';
import {useLocation} from '@app/hooks/use-location';

export const HeaderOpenPlayer: FunctionComponent<ButtonProps> = (props) => {
	const {t} = useTranslation();
	const {getDeezerUrl} = useLocation();

	return (
		<Button as="a" href={getDeezerUrl('/')} variant="outline" {...props}>
			{t('generic_action_openplayer_standalone-features')}
		</Button>
	);
};
