import type {FunctionComponent} from 'react';
import React from 'react';
import {LandingGoToButton, LandingPage} from '@tempo/landing';
import {Box} from '@tempo/core';
import {useRouter} from 'next/dist/client/router';
import dynamic from 'next/dynamic';
import {NextSeo} from 'next-seo';
import Header from './tempo-header';
import metas from 'locales/metas.json';

const LazyFooter = dynamic(() => import('./tempo-footer'), {
	loading: () => <footer />,
});

type TempoPageProps = {
	initialMode?: 'dark' | 'light';
	title: string;
	description?: string;
	openedLocales?: string[];
	footer?: React.ReactNode;
	header?: React.ReactNode;
	goToBtn?: React.ReactNode;
};

export const TempoLandingPage: FunctionComponent<
	React.PropsWithChildren<TempoPageProps>
> = ({
	children,
	title,
	description,
	header,
	footer,
	goToBtn = <LandingGoToButton zIndex={2} />,
	openedLocales,
}) => {
	const {locales} = useRouter();

	return (
		<>
			<NextSeo
				title={title}
				description={description}
				noindex={!description}
				nofollow={!description}
				openGraph={
					description
						? {
								title: title,
								description: description,
							}
						: undefined
				}
			/>
			<LandingPage data-cy="landingpage">
				{goToBtn}
				<Box data-cy="landingpage-content">
					{header ?? <Header />}
					{children}
				</Box>
				{footer ?? (
					<LazyFooter
						openedLocales={
							openedLocales ??
							locales.filter((lang) => !metas.sunsetLanguages.includes(lang))
						}
					/>
				)}
			</LandingPage>
		</>
	);
};
