import type {NameSpace, TranslationKey} from 'globalTranslations';
import {useTranslation as useT} from 'next-i18next';

/**
 * In order to help migrating pages faster from legacy to standalone
 * without overloading translation team, we must find a way to utilise
 * legacy keys in the meantime...
 *
 * This hook exposes a t function, default, for standalone-web keys,
 * and a t function, used for legacy keys.
 */

export const useTranslation = (config?: {ns: NameSpace[]}) => {
	const {t} = useT();

	const translate = (
		key: TranslationKey,
		options?: {
			sprintf?: (string | number)[];
			count?: number;
			ns?: NameSpace[] | NameSpace;
		},
	) => {
		return t(key, {
			ns: config?.ns,
			...options,
		});
	};

	return {
		t: translate,
		/**
		 * @deprecated - use t() instead both are doing the same thing and we'll remove gettext
		 */
		gettext: translate,
	};
};
